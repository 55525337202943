import axios from "axios";
import forge from "node-forge";
import { cloneElement, createContext } from "react";
import { read, utils } from "xlsx";

export const ServerUrl = process.env.BACKEND_URL;

// Public Key
export const publicKey = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjU8t9z0UtiSaO7JUHvMp
ln1VFJ3QJgoKyOorhWvt2Xj0jZ9d1QpD2PjV/A5n3VoOsnLzkhHOlXooSqHbuB2D
n4g6fzbI2N+Y0KggtZGhjtwVVEyaI3LMWVI+S80gRrcY+sP/SvPniuMJoJDfc1Gf
KK5lmfB2CjTCV0LZBMWA9JSiN4ngRhVQ69sAOhAo1HaY7R+CXhlSPTKfcZqkpSjj
n6DyDaQXI5YeoQOsDxDWelqftfr8lTSDh+tOkjGL7irUStaxG5zYJyYAF1OMM4Ae
x2NLD373UGtdlOT7STi3UvsNdvDpDrY5O1HteNaxC1S815UZU3U/u5lvANQB16aO
pQIDAQAB
-----END PUBLIC KEY-----
`;
export const siteKey = "6LfhyIYpAAAAAD7cGt4Ece4-00xqR00a32FRMDI3";

/**
 * @template T
 * @param {T} object
 * @returns {T}
 */
export function copy(object) {
  return Object.assign({}, object);
}

// default forms/states
export const defaults = {
  login: { email: "", password: "" },
  register: {
    personal: {
      firstName: "",
      lastName: "",
      email: "",
      participantId: "",
    },
    account: {
      drivingLicenseId: "",
      password: "",
      confirmPassword: "",
    },
  },
  changePassword: {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  },
  resetPassword: {
    email: "",
    newPassword: "",
    confirmNewPassword: "",
  },
  instructionsHA: {
    instructions: {
      "top-view": [],
      "driver-view": [],
    },
    "instructions-practice": {
      "top-view": [],
      "driver-view": [],
      "driver-view-with-answer": [],
    },
    "correct-feedback": [""],
    "correct-feedback-practice": [""],
    "incorrect-feedback": [""],
    "incorrect-feedback-practice": [""],
  },
  instructionsAM: {
    instructions: {
      "driver-view": [],
      question: [],
    },
    "instructions-practice": {
      "driver-view": [],
      question: [],
      "question-with-answer": [],
    },
    "correct-rvm": [],
    "correct-grid": [],
    "incorrect-feedback": {
      "incorrect-glance": {
        "err-duration": [],
        "err-hazard": [],
        "err-duration-hazard": [],
        "err-no-glance": [],
      },
      "incorrect-grid": [],
    },
    "incorrect-feedback-practice": {
      "incorrect-glance": {
        "err-duration": [],
        "err-hazard": [],
        "err-duration-hazard": [],
        "err-no-glance": [],
      },
      "incorrect-grid": [],
    },
  },
  responseHistory: {
    correct: 0,
    incorrect: 0,
    last: null,
  },
  activityState: {
    taskId: null,
    moduleId: null,
    scenarioId: null,
    activityId: null,
    assetPath: null,
    practiceMode: null,
    type: null,
  },
  dashboardState: {
    email: null,
    emailHash: null,
    firstName: null,
    lastName: null,
    participantId: null,
    role: null,
    postDrivingTestActivated: null,
    inPerson: null,
  },
  feedback: { type: "", content: "" },
};

/** @type {InputFilter[]} */
export const emailFilters = [
  (v) => v.replace(/\s/g, ""),
  (v) => v.replace(/[@]+/g, "@"),
];

const emptyBanner = { icon: null, text: "" };
export const BannerContext = createContext([emptyBanner, null]);

// Function to encrypt the password with the public key
export function encryptWithPublicKey(password) {
  // Convert the public key to CryptoKey object
  const publicKeyObj = forge.pki.publicKeyFromPem(publicKey);
  // Encrypt the password using the public key
  const encrypted = publicKeyObj.encrypt(password, "RSA-OAEP", {
    md: forge.md.sha256.create(),
  });

  // Return the encrypted password as Base64 string
  return forge.util.encode64(encrypted);
}

export function mask(str = "") {
  // mask at most 4 characters, but at least 1/3 of the string
  const section = Math.ceil(Math.min(str.length / 3, 4));
  const hidden = str.slice(0, -section);
  const shown = str.slice(-section);
  return hidden.replace(/./g, "\u2022") + shown;
}

export async function pause(ms = 200) {
  return new Promise((res) => setTimeout(res, ms));
}

/**
 * Make loaders less janky with consistent delay.
 *
 * - If given action returns before delay time - remaining time is awaited
 * - Else - return result immediately
 *
 * @template T
 * @param {number} timeMS - Delay
 * @param {() => Promise<T>} action
 * @returns {Promise<T>}
 */
export async function loadFor(timeMS = 0, action) {
  const startTime = Date.now();

  const res = await action?.();

  const timeLeft = timeMS + startTime - Date.now();
  if (timeLeft > 0) await pause(timeLeft);
  return res;
}

export function duplicate(element = <></>, count = 1) {
  return (
    <>
      {Array.from({ length: count }, (_v, key) => {
        return cloneElement(element, { key: key, idx: key + 1 });
      })}
    </>
  );
}

/**
 * @template T
 * @param {object} obj
 * @param {T | keyof T} filter
 * @returns {T | Map<keyof T, any>}
 */
export function getSubset(obj = {}, filter = {}) {
  let filterKeys = Object.keys(filter);
  if (filter instanceof Array) {
    filterKeys = filter;
  }
  return filterKeys.reduce((subset, key) => {
    subset[key] = obj[key];
    return subset;
  }, {});
}

/**
 * @template Obj
 * @param {Obj} obj
 * @param {(keyof Obj)[]} filter
 * @returns {Map<keyof Obj, any>}
 */
export function getSubsetAlt(obj = {}, filter = []) {
  return filter.reduce((subset, key) => {
    subset[key] = obj[key];
    return subset;
  }, {});
}

export function keyEnter(event, func) {
  if (event && event.key === "Enter") {
    return func?.(event);
  }
}

/**
 * @param {Element} element
 * @param {string} varName
 */
export function getCssVar(element, varName) {
  if (!element) return;
  return window.getComputedStyle(element).getPropertyValue(varName);
}

export function xlsxToJson(file) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: "array" });
      const firstSheet = workbook.SheetNames[0];
      const jsonData = utils.sheet_to_json(workbook.Sheets[firstSheet]);
      resolve(jsonData);
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsArrayBuffer(file);
  });
}

export function areCookiesEnabled() {
  let cookieEnabled = navigator.cookieEnabled;
  if (!cookieEnabled) {
    document.cookie = "testcookie";
    cookieEnabled = document.cookie.indexOf("testcookie") !== -1;
    if (cookieEnabled) {
      document.cookie = "testcookie=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    return cookieEnabled;
  }
  return true;
}

export function toggleActivity(activityId) {
  return activityId === "S" ? "T" : "S";
}

function getAssetPath({ moduleId, scenarioId, activityId }) {
  let newModuleId = moduleId;

  const moduleMappingForHA = {
    module_1: "module_1",
    module_2: "module_3",
    module_3: "module_5",
  };
  const moduleMappingForAM = {
    module_1: "module_2",
    module_2: "module_4",
    module_3: "module_6",
  };

  newModuleId =
    activityId === "AM"
      ? moduleMappingForAM[moduleId]
      : moduleMappingForHA[moduleId];
  const basePath = `${newModuleId}/${scenarioId}`;
  return activityId === "AM" ? basePath : `${basePath}/${activityId}`;
}

export function prevScenario(moduleId, scenarioId) {
  const scenario = scenarioId.split("_");
  const scenarioNum = parseInt(scenario[1]);
  const moduleNum = parseInt(moduleId.split("_")[1]);
  if (scenarioNum > 1) {
    return `${scenario[0]}_${scenarioNum - 1}`;
  }
  let prevScenarioName = scenario[0];
  if (moduleNum === 3) {
    prevScenarioName = "rearends";
  } else if (moduleNum === 2) {
    prevScenarioName = "intersections";
  } else if (moduleNum === 1) {
    prevScenarioName = "curves";
  }
  return `${prevScenarioName}_6`;
}

export function prevModule(moduleId) {
  const module = moduleId.split("_");
  let prevModule = parseInt(module[1]) - 1;
  if (prevModule < 1) {
    prevModule = 1;
  }
  return `module_${Math.min(prevModule, 3)}`;
}

export function prevTraining({
  taskId = "",
  moduleId = "",
  scenarioId = "",
  activityId = "",
}) {
  const moduleNum = parseInt(moduleId.split("_")[1]);
  const scenarioNum = parseInt(scenarioId.split("_")[1]);

  let prevActivity = {};

  if (activityId === "S" || activityId === "AM") {
    if (scenarioNum === 1 && moduleNum === 1) {
      // if first activity of first module, stay at current activity, do not go to last module
      prevActivity = {
        taskId,
        moduleId,
        scenarioId,
        activityId,
      };
    } else if (scenarioNum === 1) {
      // first activity but not of the first module, go to previous module's last activity
      prevActivity = {
        taskId,
        moduleId: prevModule(moduleId),
        scenarioId: prevScenario(moduleId, scenarioId),
        activityId: activityId === "S" ? toggleActivity(activityId) : "AM",
      };
    } else {
      // not first scenario, go to previous scenario
      prevActivity = {
        taskId,
        moduleId,
        scenarioId: prevScenario(moduleId, scenarioId),
        activityId: activityId === "S" ? toggleActivity(activityId) : "AM",
      };
    }
    // not first activity of scenario, previous activity
  } else {
    prevActivity = {
      taskId,
      moduleId,
      scenarioId,
      activityId: "S",
    };
  }
  prevActivity.assetPath = getAssetPath(prevActivity);
  prevActivity.type = activityId === "AM" ? "AM" : "HA";
  prevActivity.practiceMode = false;
  return prevActivity;
}

export function nextScenario(moduleId, scenarioId) {
  const scenario = scenarioId.split("_");
  const scenarioNum = parseInt(scenario[1]);
  const moduleNum = parseInt(moduleId.split("_")[1]);
  if (scenarioNum < 6) {
    return `${scenario[0]}_${scenarioNum + 1}`;
  }
  let nextScenarioName = scenario[0];
  if (moduleNum === 1) {
    nextScenarioName = "rearends";
  } else if (moduleNum === 2) {
    nextScenarioName = "curves";
  } else if (moduleNum === 3) {
    nextScenarioName = "intersections";
  }
  return `${nextScenarioName}_1`;
}

export function nextModule(moduleId) {
  const module = moduleId.split("_");
  let nextModule = parseInt(module[1]) + 1;
  if (nextModule > 3) {
    nextModule = 1;
  }
  return `module_${Math.max(nextModule, 1)}`;
}

export function isModulePauseEnd(startTime, pauseMins = 60) {
  const totalTime = 1000 * 60 * pauseMins;
  return parseInt(startTime) + totalTime - Date.now() <= 0;
}

export function isFirstActivityInTrainingModule({
  taskId = "",
  scenarioId = "",
  activityId = "",
}) {
  const scenarioNum = parseInt(scenarioId.split("_")[1]);
  return (
    taskId === "training" &&
    scenarioNum === 1 &&
    (activityId === "S" || activityId === "AM")
  );
}

export function isLastActivityInTrainingModule({
  taskId = "",
  scenarioId = "",
  activityId = "",
}) {
  const scenarioNum = parseInt(scenarioId.split("_")[1]);
  return (
    taskId === "training" &&
    scenarioNum === 6 &&
    (activityId === "T" || activityId === "AM")
  );
}

export function nextTraining({
  taskId = "",
  moduleId = "",
  scenarioId = "",
  activityId = "",
  practiceMode = false,
}) {
  let nextActivity = null;
  const moduleNum = parseInt(moduleId.split("_")[1]);
  const scenarioNum = parseInt(scenarioId.split("_")[1]);

  if (activityId === "T" || activityId === "AM") {
    if (scenarioNum >= 6) {
      if (moduleNum === 3) {
        // last activity of last module, return null
        return nextActivity;
      }
      // if last scenario of module, next module
      nextActivity = {
        taskId,
        moduleId: nextModule(moduleId),
        scenarioId: nextScenario(moduleId, scenarioId),
        activityId: activityId === "T" ? toggleActivity(activityId) : "AM",
      };
    } else {
      // not last scenario of module, next scenario
      nextActivity = {
        taskId,
        moduleId,
        scenarioId: nextScenario(moduleId, scenarioId),
        activityId: activityId === "T" ? toggleActivity(activityId) : "AM",
      };
    }
  } else {
    // Assumption: For training, Strategic (Far View)'s next activity is Tactical (Near-View)
    nextActivity = {
      taskId,
      moduleId,
      scenarioId,
      activityId: "T",
    };
  }
  nextActivity.assetPath = getAssetPath(nextActivity);
  nextActivity.type = activityId === "AM" ? "AM" : "HA";
  nextActivity.practiceMode = false;
  return nextActivity;
}

export function getCurrentActivityIndex({ moduleId, scenarioId, activityId }) {
  return `${moduleId}_${scenarioId}_${activityId}`;
}

export function nextTest(
  { taskId = "", moduleId = "", scenarioId = "", activityId = "" },
  activityOrder,
) {
  const currentActivityIndex = getCurrentActivityIndex({
    moduleId,
    scenarioId,
    activityId,
  });
  const nextActivityIndex = activityOrder[currentActivityIndex].next;

  return nextActivityIndex === null
    ? null
    : {
        taskId: activityOrder[nextActivityIndex].taskId,
        type: activityOrder[nextActivityIndex].type,
        moduleId: activityOrder[nextActivityIndex].moduleId,
        scenarioId: activityOrder[nextActivityIndex].scenarioId,
        activityId: activityOrder[nextActivityIndex].activityId,
        assetPath: activityOrder[nextActivityIndex].assetPath,
        practiceMode: activityOrder[nextActivityIndex].practiceMode,
      };
}

export function calculateTrainingProgress(
  moduleId,
  scenarioId,
  activityId,
  isPracticeMode,
  isCompleted,
) {
  if (isPracticeMode) {
    return {
      modulePercentage: 0,
      overallPercentage: 0,
    };
  }

  const moduleNum = moduleId.split("_")[1] - 1;
  const scenarioNum = scenarioId.split("_")[1] - 1;
  const activityDone = activityId === "T";
  const percent = (2 * scenarioNum + activityDone) / 12;
  return {
    modulePercentage: isCompleted ? 100 : Math.floor(percent * 100),
    overallPercentage: isCompleted ? 100 : (100 * (moduleNum + percent)) / 3,
  };
}

export function calculateTestingProgress(
  scenarioId,
  activityId,
  isPracticeMode,
  isValidationStudy = false,
  isCompleted = false,
) {
  if (isPracticeMode) {
    return {
      modulePercentage: 0,
      overallPercentage: 0,
    };
  }

  const activityNum = getNumActivitiesBeforeScenarioForTest(
    scenarioId,
    isValidationStudy,
  );

  const lastActivityInScenario = ["T", "AM"].includes(activityId);
  const numActivities = isValidationStudy ? 27 : 18;
  const percent = (activityNum + lastActivityInScenario) / numActivities;
  const progress = isCompleted ? 100 : Math.floor(percent * 100);

  return {
    modulePercentage: progress,
    overallPercentage: progress,
  };
}

export function getNumActivitiesBeforeScenarioForTest(
  scenarioId,
  isValidationStudy,
) {
  let scenarioName = scenarioId.split("_")[0];
  let scenarioNum = scenarioId.split("_")[1] - 1;

  if (scenarioName === "rearends") {
    scenarioNum += isValidationStudy ? 6 : 3;
  } else if (scenarioName === "curves") {
    scenarioNum += isValidationStudy ? 12 : 6;
  }

  if (isValidationStudy) {
    return Math.floor(scenarioNum / 2) * 2 + Math.floor(scenarioNum / 2);
  } else {
    return 2 * scenarioNum;
  }
}

export function getTaskName(taskId = "", role = "") {
  if (!taskId) return null;
  const taskNames = {
    preTest: "Hazard Perception Quiz 1",
    training:
      role === "EXPERIMENT_AM"
        ? "Attention Maintenance Training"
        : "Hazard Perception Training",
    postTest: "Hazard Perception Quiz 2",
    postDrivingTest: "Hazard Perception Quiz 3",
  };
  return taskNames[taskId];
}

export function getSkillType(moduleId = "") {
  if (!moduleId) return null;
  const skills = ["Hazard Anticipation", "Attention Maintenance"];
  const moduleNum = parseInt(moduleId.split("_")[1]);
  const skillIdx = moduleNum % 2 || 2;
  return skills[skillIdx - 1];
}

export function getModuleName(moduleId = "") {
  if (!moduleId) return null;
  const moduleNum = moduleId.split("_")[1];
  return `Module ${moduleNum}`;
}

export function getScenarioType(moduleId = "") {
  if (!moduleId) return null;
  const scenarioTypes = ["Intersections", "Rear-Ends", "Curves"];
  const moduleNum = parseInt(moduleId.split("_")[1]) - 1;
  const typeIdx = Math.floor(moduleNum / 2);
  return scenarioTypes[Math.min(typeIdx, 2)];
}

export function getScenarioName(scenarioId = "") {
  if (!scenarioId) return null;
  const scenario = scenarioId.split("_");
  let scenarioType = "";
  if (scenario[0] === "intersections") {
    scenarioType = "Intersections";
  } else if (scenario[0] === "rearends") {
    scenarioType = "Rear-Ends";
  } else {
    scenarioType = "Curves";
  }
  // return `${scenarioType} ${scenario[1]}`;
  return `${scenarioType}`;
}

// TODO: Change Tactical Activity/Strategic Activity to Near View/Far View
export function getActivityName(activityId = "") {
  if (!activityId) return null;
  return activityId === "S" ? "Far View" : "Near View";
}

export function getModuleActivityNumber(scenarioId = "", activityId = "") {
  const scenarioNum = scenarioId.split("_")[1];
  return 2 * scenarioNum - (activityId === "S");
}

export function abbrevScenario(scenarioId) {
  const scenarioName = getScenarioName(scenarioId);
  return scenarioName[0] + scenarioName.slice(-1);
}

export function abbrevSkill(moduleId) {
  const skill = getSkillType(moduleId).split(" ");
  return skill[0][0] + skill[1][0];
}

// TODO:
export function getEntrance(fromProfile, activityState) {
  const { moduleId, activityId } = activityState || {};
  if (fromProfile) {
    return "Profile";
  } else if (activityState) {
    const entrance = [getScenarioName(moduleId)];
    if (activityId) {
      entrance.push(getActivityName(activityId));
    }
    return entrance.join(" - ");
  } else {
    return "Unknown";
  }
}

export async function fetchTestingScore(pid, tid, mid) {
  return await axios
    .get(`${ServerUrl}/users/${pid}/tasks/${tid}/modules/${mid}/status`, {
      withCredentials: true,
    })
    .then((res) => {
      const activityStateArray = res.data.data;
      return activityStateArray.filter((activity) => activity === "correct")
        .length;
    })
    .catch((err) => {
      /* handle error */
      console.log(err);
    });
}
