import { pause } from "@src/Utils";
import { Button } from "@src/components/Button";
import Modal, { swapContent } from "@src/components/Modal";
import { ContinuousProgressBar } from "@src/components/ProgressBar";
import { DisplayInfo, ModalContext } from "@src/contexts/Contexts";
import { selectActions } from "@src/workers/constants";
import { IconCheck, IconDownload } from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";

const initialState = {
  downloaded: 0,
  totalUsers: 0,
  ready: false,
  saved: false,
  link: "",
  fileName: "",
  dataSize: null,
};

/**
 * @param {{
 *   title: string;
 *   openState: UseState<boolean>;
 *   onRemap: function;
 *   onCancel: function;
 *   dataStatus: any;
 * }} props
 */
const DownloadUsersModal = (props) => {
  const { title, openState, onRemap, onCancel, dataStatus } = props;

  const { isMobile, isPortrait } = useContext(DisplayInfo);

  const [isOpen, setIsOpen] = openState;
  const [downloadState, setDownloadState] = useState({ ...initialState });

  useEffect(() => {
    if (!dataStatus) return;

    const { type, fileName, link, totalUsers, downloaded } = dataStatus;

    switch (type) {
      case selectActions.JSON_READY:
        if (downloadState.link) URL.revokeObjectURL(downloadState.link);

        setDownloadState({
          ...downloadState,
          downloaded,
          totalUsers,
          ready: true,
        });

        if (isOpen) {
          enableDownloadLink(link, fileName);
        } else if (link) {
          URL.revokeObjectURL(link);
        }
        return;

      case selectActions.JSON_ALL_STARTED:
        if (isOpen) {
          setDownloadState({ ...downloadState, totalUsers });
        }
        return;

      case selectActions.JSON_ALL_PROGRESS:
        if (isOpen && downloadState.downloaded !== downloaded) {
          setDownloadState({ ...downloadState, downloaded });
        }
        return;
    }
  }, [dataStatus]);

  function enableDownloadLink(link, fileName) {
    const units = ["B", "KB", "MB", "GB"];
    let unitIdx = 0;

    fetch(link)
      .then((res) => res.blob())
      .then((blob) => {
        let size = blob.size;

        while (size > 999) {
          size /= 1024;
          unitIdx += 1;
        }
        unitIdx = Math.min(unitIdx, 3);

        const dataSize = `${Math.round(100 * size) / 100} ${units[unitIdx]}`;

        setIsOpen((isOpen) => {
          if (!isOpen) return false;

          setDownloadState((prev) => ({
            ...prev,
            link,
            fileName,
            dataSize,
          }));

          return true;
        });
      });
  }

  function downloadJSON(link, fileName) {
    const linkBtn = document.createElement("a");
    linkBtn.href = link;
    linkBtn.download = `${fileName}.json`;
    document.body.appendChild(linkBtn);
    linkBtn.click();
    setDownloadState((prev) => ({ ...prev, saved: true }));

    document.body.removeChild(linkBtn);
    URL.revokeObjectURL(link);
  }

  async function onDownloadSave() {
    const { link, fileName } = downloadState;

    await pause(100);
    downloadJSON?.(link, fileName);
    await pause(1000);

    setIsOpen(false);
  }

  function onDownloadCancel() {
    setDownloadState((state) => {
      if (state.link) URL.revokeObjectURL(state.link);
      return state;
    });
    onCancel?.();
    setIsOpen(false);
  }

  return (
    <Modal
      id="download-modal"
      className="download-modal"
      title={title}
      contentLabel="Login Modal"
      isOpen={isOpen}
      close={onDownloadCancel}
      afterClose={() => setDownloadState({ ...initialState })}
      focusOnOpen
    >
      <Download
        state={downloadState}
        onRemap={onRemap}
        onSave={onDownloadSave}
        onCancel={onDownloadCancel}
      />
    </Modal>
  );
};

/**
 * @param {{
 *   state: typeof initialState;
 *   onRemap: function;
 *   onSave: function;
 *   onCancel: function;
 * }} props
 */
const Download = (props) => {
  const { state, onRemap, onSave, onCancel } = props;

  const { isMobile, isTablet, isPortrait } = useContext(DisplayInfo);
  const { contentElement } = useContext(ModalContext);

  const [dataReady, setDataReady] = useState(state.ready);
  const [loadingRemap, setLoadingRemap] = useState(false);

  const usersTxt = `${state.downloaded === 1 ? "User" : "Users"}`;

  useEffect(() => {
    if (loadingRemap) setLoadingRemap(false);
  }, [state.link]);

  useEffect(() => {
    if (state.ready) {
      swapContent(contentElement, async () => {
        await pause(150);
        setDataReady(true);
        await pause(100);
      });
    }
  }, [state.ready]);

  function remapData() {
    // TODO
    setLoadingRemap(true);
    onRemap?.();
  }

  const downloadLabel = (
    <>
      {isMobile && isPortrait ? "" : isTablet ? "JSON" : "Download JSON"}
      <span className="file-size">{state.dataSize}</span>
    </>
  );

  return (
    <>
      <p className="download-progress">
        {dataReady ? (
          <span>
            <b className="total">{state.downloaded}</b> {usersTxt} Total
          </span>
        ) : (
          <>
            <span>
              {state.downloaded / state.totalUsers == 1
                ? "Aggregating..."
                : "Fetching Users..."}
            </span>

            {state.totalUsers > 0 && (
              <span>
                {state.downloaded} / {state.totalUsers} Users
              </span>
            )}
          </>
        )}
      </p>

      <ContinuousProgressBar
        className="download-progress-bar"
        initAnimate
        percentage={(100 * state.downloaded) / state.totalUsers}
      />

      {dataReady && (
        <>
          <p className="download-ready">
            <b>Coming soon...</b>
            <br />
            <br />
            <span>JSON user data currently includes:</span>
          </p>
          <ul className="download-note">
            <li>user info: participantId & role</li>
            <li>
              responseStartTimes & responseEndTimes for completed activities
            </li>
          </ul>
        </>
      )}

      <span className="btn-row">
        <Button
          className="cancel-download-file"
          variant="secondary"
          label="Cancel"
          onClick={onCancel}
        />
        <Button
          className="download-file"
          variant={dataReady ? "light" : "secondary"}
          label={state.saved ? "Saved" : downloadLabel}
          icon={
            state.saved ? (
              <IconCheck stroke={2.8} scale={1.1} />
            ) : (
              <IconDownload stroke={2.6} />
            )
          }
          gap="0.6rem"
          loading={!state.link || !dataReady || loadingRemap}
          onClick={onSave}
          styles={{ width: "100%" }}
        />
      </span>
    </>
  );
};

export default DownloadUsersModal;
