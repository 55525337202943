import { useOnResize, useStateRef } from "@src/Hooks";
import { IconCheck, IconChevronLeft } from "@tabler/icons-react";
import { useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { pause } from "../Utils";
import { ScenarioInfo } from "../contexts/Contexts";
import { Button } from "./Button";
import { parseMarkdown } from "./InstructionParser";
import { SVG } from "./SVG";
import TextBubble from "./TextBubble";
import Tooltip from "./Tooltip";

export function useScenarioIntro() {
  const userInfo = useLocation().state || {};
  const { activityState } = useContext(ScenarioInfo).states;

  const activityType = activityState.type;
  const isPractice = activityState.practiceMode;
  const isTest = activityState.taskId === "preTest";
  const isValidationStudy = userInfo.role === "VALIDATION_STUDY";
  const emailHash = userInfo.emailHash;

  const preSlidesLSKey = `${emailHash}-intro-pre-${activityType}-visited`;
  const introDemoLSKey = `${emailHash}-intro-demo-${activityType}-visited`;
  const postSlidesLSKey = `${emailHash}-intro-post-${activityType}-visited`;
  const preTestIntroLSKey = `${emailHash}-pretest-intro-visited`;

  if (!activityType) return {};

  const { initPre, initPost, initPreTest, allVisited } = checkIntros();
  const [showPreIntro, setShowPreIntro] = useState(initPre);
  const [showPostIntro, setShowPostIntro] = useState(initPost);
  const [showPreTestIntro, setShowPreTestIntro] = useState(initPreTest);
  const [isIntro, setIsIntro] = useState(isTest && !allVisited);

  const [introContinue, setIntroContinue] = useState(false);
  const [targetList, setTargetList, targetListRef] = useStateRef([]);
  const [currTarget, setCurrTarget, currTargetRef] = useStateRef(null);
  const [currDescription, setCurrDescription] = useState(null);
  const [introSlides, setIntroSlides] = useState({});
  const currTooltipRef = useRef(null);
  const remainingRef = useRef([]);
  const visitedChecks = useRef([]);

  const introIndicator = document.createElement("div");
  introIndicator.classList.add("intro-indicate");
  introIndicator.tabIndex = 1;

  useEffect(() => {
    if (isIntro) {
      parseMarkdown("intro").then(setIntroSlides);
      document.body.classList.add("intro");
      modifyTabNavigation();
    } else {
      document.body.classList.remove("intro");
    }
  }, [isIntro]);

  useEffect(() => {
    if (!introSlides["layout-descriptions"]) return;

    const PreTestIntroSlides = isValidationStudy
      ? introSlides["pre-test-intro-val"]
      : introSlides["pre-test-intro-no-val"];

    if (!introSlides["pre-intro"][activityType.toLowerCase()].length) {
      localStorage.setItem(preSlidesLSKey, "true");
    }
    if (!introSlides["post-intro"][activityType.toLowerCase()].length) {
      localStorage.setItem(postSlidesLSKey, "true");
    }
    if (!PreTestIntroSlides.length) {
      localStorage.setItem(preTestIntroLSKey, "true");
    }
  }, [introSlides]);

  useEffect(() => {
    checkIntros(true);
  }, [activityType, isTest, isPractice]);

  // prettier-ignore
  useOnResize(() => {
    if (!isIntro) return;
    reduceTargets();
  }, [targetList], 150);

  useEffect(() => {
    if (!isIntro) return;
    for (let element of targetList) {
      const firstChild = element.childNodes[0];
      if (!element.offsetHeight || !firstChild) continue;
      if (!firstChild.classList.contains("intro-indicate")) {
        attachIndicator(element);
      }
    }
  }, [targetList]);

  function checkIntros(
    setState = false,
    updatedType = activityType,
    updatedTest = isTest,
    updatedPractice = isPractice,
  ) {
    const preSlidesLSKey = `${emailHash}-intro-pre-${updatedType}-visited`;
    const introDemoLSKey = `${emailHash}-intro-demo-${updatedType}-visited`;
    const postSlidesLSKey = `${emailHash}-intro-post-${updatedType}-visited`;
    const preVisited = localStorage.getItem(preSlidesLSKey);
    const demoVisited = localStorage.getItem(introDemoLSKey);
    const postVisited = localStorage.getItem(postSlidesLSKey);
    const preTestIntroVisited = localStorage.getItem(preTestIntroLSKey);
    const practIntroVisited = preVisited && demoVisited && postVisited;

    const initPost = !postVisited && demoVisited;
    const initPreTest =
      !updatedPractice && practIntroVisited && !preTestIntroVisited;
    const allVisited =
      practIntroVisited && (updatedPractice || preTestIntroVisited);

    if (setState) {
      setShowPreIntro(!preVisited);
      setShowPostIntro(initPost);
      setShowPreTestIntro(initPreTest);
      setIsIntro(!allVisited);
      return !allVisited;
    } else
      return {
        initPre: preVisited,
        initPost,
        initPreTest,
        allVisited,
      };
  }

  function modifyTabNavigation() {
    const focusable = document.body.querySelectorAll("[tabindex]");
    focusable.forEach((elt) => {
      if (!elt.classList.contains("intro-indicate")) {
        elt.tabIndex = -1;
      }
    });
  }

  function addIntroTarget(newRef) {
    if (!isIntro || !newRef) return;
    const targets = targetListRef.current;
    const notIncluded = !targets.includes(newRef);
    if (targetPresent(newRef) && notIncluded) {
      setTargetList([...targetList, newRef]);
      remainingRef.current = [...targetList, newRef];
    }
  }

  function introOnClick(event) {
    if (!isIntro) return;
    const element = event.target.parentNode;
    visitTarget(element);
    setIntroSlides((curr) => {
      updateDescription(curr["layout-descriptions"], element);
      return curr;
    });
    setCurrTarget(event.target);
    const inst = currTooltipRef.current;
    pause(200).then(() => {
      element.classList.add("visited");
    });
    if (!inst || inst.state.isDestroyed) return;
    const show = currTargetRef.current !== event.target;
    if (show) inst.show();
    else inst.hide();
  }

  function introOnKeyDown(event) {
    if (event.key === "Enter") {
      introOnClick(event);
    }
  }

  function visitTarget(element) {
    const targetsLeft = remainingRef.current;
    const idx = targetsLeft.indexOf(element);
    if (idx !== -1) {
      targetsLeft.splice(idx, 1);
    }
    if (!targetsLeft.length) {
      setIntroContinue(true);
      localStorage.setItem(introDemoLSKey, "true");
    }
  }

  function updateDescription(layoutDescriptions, element) {
    for (let str of element.classList) {
      if (!layoutDescriptions[str.toLowerCase()]) continue;
      let desc = layoutDescriptions[str.toLowerCase()];
      if (desc["am"] && desc["ha"]) {
        desc = desc[activityType.toLowerCase()];
      }
      setCurrDescription(desc);
      break;
    }
  }

  function attachIndicator(element) {
    const indicator = introIndicator.cloneNode();
    const position = window.getComputedStyle(element).position;
    if (position === "static") {
      element.style.position = "relative";
    }
    const duration = (1 + Math.random() / 3).toFixed(2);
    const delay = 2 * Math.random().toFixed(2);
    indicator.style.animationDuration = `${duration}s`;
    indicator.style.animationDelay = `${delay}s`;
    indicator.onclick = introOnClick;
    indicator.onkeydown = introOnKeyDown;
    const check = createPortal(
      <IconCheck className="icon-visited" />,
      indicator,
    );
    visitedChecks.current = [...visitedChecks.current, check];
    element.prepend(indicator);
    return indicator;
  }

  function reduceTargets() {
    let modified = false;
    let oldTargets = targetListRef.current;
    let checkedTargets = [];
    for (let element of oldTargets) {
      if (targetPresent(element)) {
        checkedTargets.push(element);
      } else {
        modified = true;
      }
    }
    if (modified) {
      setTargetList([...checkedTargets]);
      remainingRef.current = [...checkedTargets];
      setCurrTarget(null);
      const tooltip = currTooltipRef.current;
      if (tooltip && !tooltip.state.isDestroyed) {
        tooltip.hide();
      }
    }
    return checkedTargets;
  }

  function targetPresent(element) {
    if (!element) return false;
    const attached = document.contains(element);
    const visible = element.offsetWidth;
    return attached && visible;
  }

  async function tooltipOnHide(inst) {
    if (!inst.state.isShown) return;
    const oldRef = inst.reference;
    await pause(inst.props.duration);
    if (oldRef == currTargetRef.current) {
      setCurrTarget(null);
    }
  }

  const tooltip = targetPresent(currTarget) && (
    <Tooltip
      interactive
      className="intro-info"
      onCreate={(inst) => {
        currTooltipRef.current = inst;
        inst.show();
      }}
      onHide={tooltipOnHide}
      content={
        <div
          dangerouslySetInnerHTML={{
            __html: currDescription,
          }}
        />
      }
      allowHTML
      triggerTarget={currTarget}
      reference={currTarget}
      appendTo={document.body}
      placement="right"
      popperOptions={{
        modifiers: [
          {
            name: "flip",
            options: {
              fallbackPlacements: ["left", "top", "bottom"],
            },
          },
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              boundary: document.getElementById("root"),
            },
          },
        ],
      }}
    />
  );

  const introContent = (
    <>
      {(showPreIntro || showPostIntro || showPreTestIntro) && (
        <AvatarOverlay
          pre={showPreIntro}
          post={showPostIntro}
          preTest={showPreTestIntro}
          preLSKey={preSlidesLSKey}
          postLSKey={postSlidesLSKey}
          preTestLSKey={preTestIntroLSKey}
          activityType={activityType}
          isValidationStudy={isValidationStudy}
          introSlides={introSlides}
          onCompletion={() => checkIntros(true)}
        />
      )}
      {visitedChecks.current}
      {tooltip}
    </>
  );

  return {
    isIntro,
    introInstruction: introSlides["sample-instructions"],
    introContinue,
    addIntroTarget,
    introProgressData: 100 / 4, // 25% completion
    checkIsIntro: (type, isTest, prac) => checkIntros(true, type, isTest, prac),
    contentPortal: createPortal(introContent, document.body),
  };
}

/**
 * @param {{
 *   pre: boolean;
 *   post: boolean;
 *   preTest: boolean;
 *   preLSKey: string;
 *   postLSKey: string;
 *   preTestLSKey: string;
 *   activityType: string;
 *   onCompletion: function;
 *   introSlides: object;
 * }} props
 */
const AvatarOverlay = (props) => {
  const showPreIntro = props.pre;
  const showPostIntro = props.post;
  const showPreTestIntro = props.preTest;
  const activityType = props.activityType;
  const isValidationStudy = props.isValidationStudy;
  const introSlides = props.introSlides;

  const [slideArray, setSlideArray] = useState([]);
  const [slideIdx, setSlideIdx] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let key = "";
    let newSlides = [];
    if (!introSlides["layout-descriptions"]) return;

    if (showPreIntro) {
      newSlides = introSlides["pre-intro"][activityType.toLowerCase()];
      key = props.preLSKey;
    } else if (showPostIntro) {
      newSlides = introSlides["post-intro"][activityType.toLowerCase()];
      key = props.postLSKey;
    } else if (showPreTestIntro) {
      // TODO
      newSlides = isValidationStudy
        ? introSlides["pre-test-intro-val"]
        : introSlides["pre-test-intro-no-val"];
      key = props.preTestLSKey;
    } else return;

    if (key && !newSlides.length) {
      localStorage.setItem(key, "true");
      props.onCompletion();
    } else {
      setSlideArray(newSlides);
    }
  }, [introSlides, showPreIntro, showPostIntro, showPreTestIntro]);

  return (
    <div className="avatar-intro-overlay">
      <div className="intro-slide">
        <TextBubble
          innerHtml={slideArray[slideIdx]}
          tailPercentOffset={100}
          indicateOn={[slideIdx]}
          indicateDelayMS={100}
          indicateScroll
          tailPlacement="left"
        />
      </div>
      <SVG src="avatar-default" className="intro-avatar" />
      <span className="intro-nav-buttons">
        <Button
          fontSize="1.5rem"
          icon={<IconChevronLeft stroke={2.8} />}
          onClick={() => setSlideIdx(slideIdx - 1)}
          variant={slideIdx <= 0 && "secondary"}
          disabled={slideIdx <= 0}
        />
        <Button
          label={
            showPreTestIntro
              ? "Begin Quiz"
              : slideIdx + 1 >= slideArray.length
                ? "Start Tutorial"
                : "Continue"
          }
          fontSize="1.5rem"
          onClick={() => {
            if (slideIdx + 1 < slideArray.length) {
              setSlideIdx(slideIdx + 1);
              return;
            }
            if (showPreIntro) {
              localStorage.setItem(props.preLSKey, "true");
            } else if (showPostIntro) {
              localStorage.setItem(props.postLSKey, "true");
            } else if (showPreTestIntro) {
              localStorage.setItem(props.preTestLSKey, "true");
              navigate(0);
              return;
            }
            props.onCompletion();
          }}
        />
      </span>
    </div>
  );
};
