import {
  IconCircle,
  IconCircleLetterAFilled,
  IconCircleLetterCFilled,
  IconCircleLetterHFilled,
  IconCircleLetterVFilled,
} from "@tabler/icons-react";
import { clsx } from "clsx";

const StudyIndicator = ({ role, letter, zIndex }) => {
  let className = null;
  let Icon = IconCircle;

  let parsedLetter = letter;
  if (!parsedLetter) {
    parsedLetter =
      role === "VALIDATION_STUDY"
        ? "V"
        : role === "EXPERIMENT"
          ? "H"
          : role === "CONTROL"
            ? "C"
            : role === "EXPERIMENT_AM"
              ? "A"
              : "V";
  }

  switch (parsedLetter) {
    case "H":
      Icon = IconCircleLetterHFilled;
      className = "f-teal";
      break;
    case "A":
      Icon = IconCircleLetterAFilled;
      className = "f-blue";
      break;
    case "C":
      Icon = IconCircleLetterCFilled;
      className = "f-violet";
      break;
    case "V":
      Icon = IconCircleLetterVFilled;
      className = "f-yellow";
      break;
  }

  return (
    <Icon
      className={clsx("symbol", className)}
      size="2rem"
      style={{ zIndex }}
    />
  );
};

export default StudyIndicator;
