import { Button } from "@src/components/Button";
import Card from "@src/components/Card";
import CheckBox from "@src/components/CheckBox";
import DashContainer from "@src/components/DashContainer";
import DownloadUsersModal from "@src/components/DownloadUsersModal";
import PagerFooter from "@src/components/PagerFooter";
import RadioButton from "@src/components/RadioButton";
import UserListCard, { UserListHeader } from "@src/components/UserListCard";
import VirtualizedScrollList from "@src/components/VirtualizedScrollList";
import { DisplayInfo } from "@src/contexts/Contexts";
import { selectActions } from "@src/workers/constants";
import SelectionWorker from "@src/workers/userListWorker.js?worker";
import {
  IconChevronDown,
  IconDownload,
  IconFilter,
  IconListSearch,
  IconLoader2,
  IconReportAnalytics,
  IconSearch,
  IconX,
} from "@tabler/icons-react";
import axios from "axios";
import clsx from "clsx";
import { ClickScrollPlugin, OverlayScrollbars } from "overlayscrollbars";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { ServerUrl, pause } from "../../Utils";
import InputField, {
  InputHeader,
  SelectField,
} from "../../components/InputField";
import StudyIndicator from "../../components/StudyIndicator";
import "../../styles/research-search.css";

/** @type {Worker} */
const selectionWorker = new SelectionWorker();

export default function SearchUI() {
  document.body.id = "research-search";
  const navigate = useNavigate();
  const userInfo = useLocation().state;

  const { isHighDPI } = useContext(DisplayInfo);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [userData, setUserData] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [modalState, setModalState] = useState({ action: "enablePostTest" });
  const [isActionsOpen, setIsActionsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [stableSearch, setStableSearch] = useState("");
  const [page, setPage] = useState(1);
  const [filterOptions, setFilterOptions] = useState({
    task: null,
    study: new Set(),
    completion: new Set(),
    quiz3Status: undefined,
  });
  const [searchBy, setSearchBy] = useState("name");
  const [numUsers, setNumUsers] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [viewSelected, setViewSelected] = useState(false);
  const [hideFilters, setHideFilters] = useState(!isHighDPI);
  const selectPreviewDelay = Math.max(300, userData?.length * 20);
  const overlayFilters = !isHighDPI;

  OverlayScrollbars.plugin(ClickScrollPlugin);

  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [downloadModalTitle, setDownloadModalTitle] = useState("");
  const [downloadStatus, setDownloadStatus] = useState(null);

  const task = {
    QUIZ_1: "preTest",
    TRAINING: "training",
    QUIZ_2: "postTest",
    QUIZ_3: "postDrivingTest",
  };
  const study = {
    VALIDATION_STUDY: "VALIDATION_STUDY",
    EXPERIMENT: "EXPERIMENT",
    EXPERIMENT_AM: "EXPERIMENT_AM",
    CONTROL: "CONTROL",
  };
  const completion = {
    NOT_STARTED: "notstarted",
    IN_PROGRESS: "inprogress",
    COMPLETED: "completed",
  };
  const quiz3Status = {
    NOT_ACTIVATED: false,
    ACTIVATED: true,
  };

  const searchByOptions = {
    participantId: "Participant ID",
    name: "Name",
    email: "Email",
  };

  useEffect(() => {
    if (userInfo?.role !== "RESEARCHER") {
      return navigate("/unauthorized");
    }
    selectionWorker.onmessage = handleSelectionUpdate;
  }, []);

  useEffect(() => {
    const searchStabilizer = setTimeout(() => {
      setStableSearch(search);
    }, 1000);

    return () => clearTimeout(searchStabilizer);
  }, [search]);

  useEffect(() => {
    if (page === 1) {
      if (!filterOptions.task) return;
      updateUserList();
    } else {
      setPage(1);
    }
  }, [filterOptions, itemsPerPage, stableSearch, !!stableSearch && searchBy]);

  useEffect(() => {
    if (!filterOptions.task) return;
    updateUserList(page);
  }, [page]);

  function batchActivateQuiz3() {
    axios
      .post(
        `${ServerUrl}/users/batch-activate-post-driving-test-user`,
        { userIds: selectedIDs },
        { withCredentials: true },
      )
      .then((res) => {
        console.log({ response: res, message: "Users sucessfully activated" });
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  /** @type {Worker["onmessage"]} */
  async function handleSelectionUpdate({ data: workerData }) {
    const { type, users = "[]", ids = "[]", ...data } = workerData;

    const userIDs = JSON.parse(ids);

    switch (type) {
      case selectActions.FETCH:
        const userList = JSON.parse(users);
        setUserData(userList);
        if (!userList.length) await pause(500);
        setFetching(false);
        setNumUsers(data.totalUsers);
        return;

      case selectActions.UPDATE_LIST:
        setAllSelected(data.allSelected);
        return;

      case selectActions.SELECT:
        break;

      case selectActions.DESELECT:
        if (!userIDs.length) {
          setViewSelected(false);
        }
        break;

      case selectActions.SELECT_ALL:
        setAllSelected(true);
        break;

      case selectActions.DESELECT_ALL:
        setViewSelected(false);
        setAllSelected(false);
        break;

      case selectActions.JSON_ALL_STARTED:
      case selectActions.JSON_ALL_PROGRESS:
      case selectActions.JSON_READY:
        setDownloadStatus(workerData);
        return;

      default:
        break;
    }

    setSelectedIDs(userIDs);

    setViewSelected((viewingSelected) => {
      setSelectedUsers((curSelected) => {
        const moreUsers = curSelected.length < userIDs.length;

        if (!viewingSelected || moreUsers) {
          const userList = JSON.parse(users);
          return userList;
        }

        return curSelected;
      });

      return viewingSelected;
    });
  }

  function selectUser(user) {
    selectionWorker.postMessage({
      action: selectActions.SELECT,
      id: user.participantId,
    });
  }

  function deselectUser(user) {
    selectionWorker.postMessage({
      action: selectActions.DESELECT,
      id: user.participantId,
    });
  }

  /** @param {MouseEvent | null} event */
  function selectAll(event) {
    if (event?.shiftKey) return deselectAll();

    if (allSelected) return;
    selectionWorker.postMessage({
      action: selectActions.SELECT_ALL,
    });
  }

  /** @param {MouseEvent | null} event */
  function deselectAll(event) {
    if (event?.shiftKey) return selectAll();

    selectionWorker.postMessage({
      action: selectActions.DESELECT_ALL,
    });
  }

  async function requestSelectionJSON() {
    setDownloadStatus({
      type: selectActions.JSON_READY,
      downloaded: selectedIDs.length,
      totalUsers: selectedIDs.length,
    });

    await pause(100);
    setDownloadModalOpen(true);
    setDownloadModalTitle("Download Selection");

    selectionWorker.postMessage({
      action: selectActions.JSON,
    });
  }

  function requestAllUsersJSON() {
    setDownloadModalOpen(true);
    setDownloadModalTitle("Download All Users");

    selectionWorker.postMessage({
      action: selectActions.JSON_ALL,
    });
  }

  function requestJSONRemap() {
    selectionWorker.postMessage({
      action: selectActions.JSON_REMAP,
    });
  }

  function cancelUsersJSON() {
    selectionWorker.postMessage({
      action: selectActions.JSON_CANCEL,
    });
  }

  function handleCheckbox(user = null, selected) {
    selected ? deselectUser(user) : selectUser(user);
  }

  function updateUserList(page = 1) {
    setViewSelected(false);

    const url = new URL(`${ServerUrl}/users/search`);
    const options = [
      searchBy,
      filterOptions["task"],
      ...filterOptions["completion"],
      ...filterOptions["study"],
    ];
    options.map((option) => {
      url.searchParams.set(option, true);
    });

    if (filterOptions.quiz3Status !== undefined) {
      url.searchParams.set(
        "postDrivingTestActivated",
        Number(filterOptions.quiz3Status),
      );
    }

    url.searchParams.set("page", page);
    url.searchParams.set("pageSize", itemsPerPage);
    if (stableSearch !== "") {
      url.searchParams.set("search", stableSearch);
    }

    setFetching(true);
    setUserData([]);
    selectionWorker.postMessage({
      action: selectActions.FETCH,
      url: url.href,
    });
  }

  function taskOnChange(task) {
    setFilterOptions({ ...filterOptions, task });
  }

  /**
   * @param {keyof typeof filterOptions} filterSet
   * @param {string} filterValue
   */
  function toggleFilterCheck(filterSet, filterValue) {
    setFilterOptions((curFilters) => {
      if (curFilters[filterSet]?.has?.(filterValue)) {
        curFilters[filterSet]?.delete?.(filterValue);
      } else {
        curFilters[filterSet]?.add?.(filterValue);
      }
      return { ...curFilters };
    });
  }

  function quiz3StatusOnChange(quiz3Status) {
    let status = quiz3Status;
    if (filterOptions.quiz3Status === status) {
      status = undefined;
    }
    setFilterOptions({ ...filterOptions, quiz3Status: status });
  }

  function selectedUserPreviews() {
    const previews = [];

    for (let user of selectedUsers) {
      if (previews.length >= 3) break;
      if (!selectedIDs.includes(user.participantId)) continue;

      previews.push(
        <StudyIndicator
          key={previews.length}
          role={user.role}
          zIndex={3 - previews.length}
        />,
      );
    }

    return previews;
  }

  const filterLayout = (
    <>
      <SelectField
        id="search-by"
        className="filter-search"
        label="Search By"
        value={searchBy}
        options={searchByOptions}
        onChange={(e) => setSearchBy(e.target.value)}
        disabled={!filterOptions.task}
      />

      <div className="filter-group">
        <InputHeader className="filter-header" label="Task" />
        <TaskSelect
          label="Quiz 1"
          task={task.QUIZ_1}
          onChange={taskOnChange}
          filterOptions={filterOptions}
        />
        <TaskSelect
          label="Training"
          task={task.TRAINING}
          onChange={taskOnChange}
          filterOptions={filterOptions}
        />
        <TaskSelect
          label="Quiz 2"
          task={task.QUIZ_2}
          onChange={taskOnChange}
          filterOptions={filterOptions}
        />
        <TaskSelect
          label="Quiz 3"
          task={task.QUIZ_3}
          onChange={taskOnChange}
          filterOptions={filterOptions}
        />
      </div>

      <div className="filter-group">
        <InputHeader className="filter-header" label="Study" />
        <Filter
          filterFor="study"
          label="Validation"
          letter="V"
          option={study.VALIDATION_STUDY}
          filterOptions={filterOptions}
          onToggle={toggleFilterCheck}
        />
        <Filter
          filterFor="study"
          label="Experiment HA"
          letter="H"
          option={study.EXPERIMENT}
          filterOptions={filterOptions}
          onToggle={toggleFilterCheck}
        />
        <Filter
          filterFor="study"
          label="Experiment AM"
          letter="A"
          option={study.EXPERIMENT_AM}
          filterOptions={filterOptions}
          onToggle={toggleFilterCheck}
        />
        <Filter
          filterFor="study"
          label="Control"
          letter="C"
          option={study.CONTROL}
          filterOptions={filterOptions}
          onToggle={toggleFilterCheck}
        />
      </div>

      <div className="filter-group">
        <InputHeader className="filter-header" label="Completion" />
        <Filter
          filterFor="completion"
          label="Not Started"
          option={completion.NOT_STARTED}
          onToggle={toggleFilterCheck}
          filterOptions={filterOptions}
        />
        <Filter
          filterFor="completion"
          label="In Progress"
          option={completion.IN_PROGRESS}
          onToggle={toggleFilterCheck}
          filterOptions={filterOptions}
        />
        <Filter
          filterFor="completion"
          label="Complete"
          option={completion.COMPLETED}
          onToggle={toggleFilterCheck}
          filterOptions={filterOptions}
        />
      </div>

      <div>
        <InputHeader className="filter-header" label="Quiz 3 Status" />
        <RadioButton
          label="Activated"
          selected={filterOptions.quiz3Status === quiz3Status.ACTIVATED}
          onChange={() => quiz3StatusOnChange(quiz3Status.ACTIVATED)}
          disabled={!filterOptions.task}
        />
        <RadioButton
          label="Not Activated"
          selected={filterOptions.quiz3Status === quiz3Status.NOT_ACTIVATED}
          onChange={() => quiz3StatusOnChange(quiz3Status.NOT_ACTIVATED)}
          disabled={!filterOptions.task}
        />
      </div>
    </>
  );

  if (userInfo?.role !== "RESEARCHER") return;

  return (
    <DashContainer
      userInfo={userInfo}
      afterTitle={
        <InputField
          className="user-search"
          type="text"
          id="search"
          autoComplete="search"
          value={search}
          placeholder={`Search by ${searchByOptions[searchBy]}`}
          iconLeft={<IconSearch />}
          width="18rem"
          onChange={(e) => setSearch(e.target.value)}
          disabled={!filterOptions.task}
        />
      }
    >
      <Helmet>
        <title>Search | Hazard Perception</title>
      </Helmet>
      <div
        className={clsx(
          "main-search-container",
          hideFilters && "filters-hidden",
          overlayFilters && "overlay-filters",
        )}
      >
        <Card className="filter-container" bgColor="var(--main-bg-color)">
          <div className="title">
            Filters
            <IconX
              className="close-filters"
              size="1.8rem"
              stroke={3}
              onClick={() => setHideFilters(true)}
              style={{ marginLeft: "auto" }}
            />
          </div>
          <OverlayScrollbarsComponent
            className="filter-list"
            options={{ scrollbars: { clickScroll: true } }}
            defer
          >
            {filterLayout}
          </OverlayScrollbarsComponent>
        </Card>
        <div
          className="search-results"
          onClick={() => {
            if (!overlayFilters || hideFilters) return;
            setHideFilters(true);
          }}
        >
          <div className="title">
            <Button
              className={clsx(
                "open-filters",
                !hideFilters && !overlayFilters && "hidden",
              )}
              variant="secondary"
              label={!overlayFilters && "Filter"}
              icon={<IconFilter stroke={2.7} />}
              onClick={() => setHideFilters(false)}
              tippy={{
                content: "Filter Search",
                disabled: !overlayFilters,
                zIndex: 100,
              }}
              tabIndex={-1}
            />
            <header>Search Users</header>
            {/* <Button
              label="Upload Users"
              variant="secondary"
              onClick={() => navigate("/research/upload")}
            /> */}
            <Button
              className="json-all"
              variant="secondary"
              label="JSON"
              icon={<IconDownload stroke={2.6} />}
              reverse
              onClick={(e) => {
                e.target?.blur?.();
                requestAllUsersJSON();
              }}
              tippy={{
                content: "Download All Users",
                placement: "left-start",
                appendTo: document.body,
              }}
              tabIndex={-1}
            />
          </div>
          {/* TODO: select all actions (moved) */}
          <UserListHeader
            someSelected={selectedIDs.length > 0}
            allSelected={allSelected}
            onSelectAll={selectAll}
            onDeselectAll={deselectAll}
            selectDisabled={!filterOptions.task}
          />
          {!filterOptions.task ? (
            <div className="task-select-prompt">
              <span className="pad" />
              <IconReportAnalytics className="icon" stroke={1.3} />
              <span className="header">Data Browser</span>
              <span className="note">
                Please select a task in filters to view users
              </span>
              <span className="pad" />
            </div>
          ) : (
            <>
              <Card
                className={clsx(
                  "selected-users",
                  !viewSelected && "compact",
                  !selectedIDs.length && "hidden",
                  selectedIDs.length > 35 && "no-animate",
                )}
                bgColor="var(--main-bg-color)"
              >
                <div
                  className="selection-header"
                  onClick={() => setViewSelected(!viewSelected)}
                >
                  <span className="size">
                    {selectedIDs.length || "None"} Selected
                  </span>
                  <span
                    className="preview"
                    style={{
                      transitionDelay:
                        !viewSelected && `${selectPreviewDelay}ms`,
                    }}
                  >
                    {selectedUserPreviews()}
                    {selectedIDs.length > 3 && "+"}
                  </span>
                  <IconChevronDown
                    className={clsx("expand-arrow", viewSelected && "open")}
                    size="2rem"
                    stroke={2.4}
                    style={{ marginLeft: "auto" }}
                  />
                  <div
                    className="select-actions"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Button
                      className="quiz-activate"
                      variant="light"
                      label="Activate Quiz 3"
                      onClick={batchActivateQuiz3}
                      tabIndex={-1}
                    />
                    <Button
                      className="json-download"
                      variant="light"
                      icon={<IconDownload stroke={2.6} />}
                      label="JSON"
                      reverse
                      onClick={(e) => {
                        e.target.blur();
                        requestSelectionJSON();
                      }}
                      tabIndex={-1}
                    />
                  </div>
                </div>

                <VirtualizedScrollList
                  className={clsx(
                    "user-cards selected",
                    !viewSelected && "hidden",
                  )}
                  itemData={viewSelected ? selectedUsers : []}
                  adapter={SelectedUserCard}
                  adapterProps={{ selectedIDs, handleCheckbox }}
                  deps={[selectedIDs]}
                  safeArea={4}
                  hideOnDeselect
                  direction="vertical"
                  defer
                />
              </Card>

              <VirtualizedScrollList
                className={clsx(
                  "user-cards",
                  viewSelected && "hidden",
                  selectedIDs.length > 35 && "no-animate",
                )}
                itemData={viewSelected ? [] : userData}
                adapter={UserCard}
                adapterProps={{ selectedIDs, handleCheckbox }}
                deps={[selectedIDs]}
                safeArea={3}
                direction="vertical"
                defer
              >
                {!userData?.length && (
                  <div
                    className={clsx("users-not-found", !fetching && "shown")}
                  >
                    <span className="pad" />
                    <IconListSearch className="icon" stroke={1.3} />
                    <span className="header">No Users Found</span>
                    <span className="note">
                      Try again using different filters or another search query
                    </span>
                    <span className="pad" />
                  </div>
                )}
              </VirtualizedScrollList>

              {fetching && <IconLoader2 className="loading-fetch" />}
            </>
          )}
        </div>
      </div>
      <PagerFooter
        itemsPerPage={itemsPerPage}
        perPageOptions={[10, 25, 50, 75, 100]}
        onItemsPerPageChange={(e) => setItemsPerPage(e.target.value)}
        selectDisabled={!filterOptions.task}
        page={page}
        numItems={numUsers}
        shownRange={isHighDPI ? 2 : 1}
        onPageChange={(page) => setPage(page)}
      />
      {/* {isActionsOpen && (
        // TODO: not used ??
        <ResearchModal
          openState={[isActionsOpen, setIsActionsOpen]}
          modalState={modalState}
        />
      )} */}
      <DownloadUsersModal
        title={downloadModalTitle}
        openState={[downloadModalOpen, setDownloadModalOpen]}
        onRemap={requestJSONRemap}
        onCancel={cancelUsersJSON}
        dataStatus={downloadStatus}
      />
    </DashContainer>
  );
}

/**
 * @param {{
 *   label: string;
 *   task: any;
 *   onChange: function;
 *   filterOptions: {};
 * }} props
 */
const TaskSelect = (props) => {
  const { filterOptions, label, task, onChange } = props;

  return (
    <RadioButton
      label={label}
      selected={filterOptions?.task === task}
      onChange={() => onChange?.(task)}
    />
  );
};

/**
 * @param {{
 *   label: string;
 *   letter: string;
 *   option: any;
 *   filterFor: string;
 *   onToggle: function;
 *   filterOptions: {};
 * }} props
 */
const Filter = (props) => {
  const { filterOptions, filterFor, label, letter, option, onToggle } = props;

  return (
    <CheckBox
      label={label}
      state={[
        filterOptions?.[filterFor]?.has(option),
        () => onToggle?.(filterFor, option),
      ]}
      after={letter && <StudyIndicator letter={letter} />}
      disabled={!filterOptions?.task}
    />
  );
};

/** @param {ItemAdapterProps} props */
const UserCard = ({ item, visible, selectedIDs, handleCheckbox }) => (
  <UserListCard
    user={item}
    selected={selectedIDs.includes(item.participantId)}
    visible={visible}
    onToggleSelected={handleCheckbox}
  />
);

/** @param {ItemAdapterProps} props */
const SelectedUserCard = ({ item, visible, selectedIDs, handleCheckbox }) => (
  <UserListCard
    user={item}
    selected={selectedIDs.includes(item.participantId)}
    visible={visible}
    onToggleSelected={handleCheckbox}
    hideOnDeselect
  />
);
