import {
  IconCheck,
  IconInfoCircle,
  IconMinus,
  IconPlus,
} from "@tabler/icons-react";
import clsx from "clsx";
import { useContext, useRef } from "react";
import { keyEnter } from "../Utils";
import { ModalContext } from "../contexts/Contexts";
import { Button, shake } from "./Button";
import Tooltip from "./Tooltip";

/**
 * @param {{
 *   className: string;
 *   label: string;
 *   icon: "check" | "plus" | "minus";
 *   variant: ButtonProps["variant"];
 *   bgColor: string;
 *   state: [boolean, ButtonProps["onClick"]];
 *   info: JSX.Element;
 *   disabled: boolean;
 *   radio: boolean;
 *   after: any;
 * }} props
 */
const CheckBox = (props) => {
  const {
    className,
    label,
    icon = "check",
    variant,
    bgColor,
    state,
    info,
    disabled,
    radio,
    after,
  } = props;

  const [selected, toggleSelected] = state || [];
  const { contentElement } = useContext(ModalContext);
  const inactive = !selected || disabled;
  const tooltipRef = useRef(null);
  const checkRef = useRef(null);

  /** @param {MouseEvent} event */
  function handleToggleBtn(event) {
    if (disabled) {
      shake(event.target);
      return;
    }
    toggleSelected?.(event);
  }

  /** @param {MouseEvent} event */
  function clickOutsideCheck(event) {
    if (!event.target.matches(".check-box")) return;
    checkRef.current?.click();
  }

  function infoClick() {
    const tooltip = tooltipRef.current;
    tooltip?.state.isVisible ? tooltip?.hide() : tooltip?.show();
  }

  const checkIcon = radio ? (
    <div className="radio" />
  ) : icon === "check" ? (
    <IconCheck stroke={3} />
  ) : icon === "plus" ? (
    <IconPlus stroke={3} />
  ) : icon === "minus" ? (
    <IconMinus stroke={3} />
  ) : null;

  return (
    <span
      className={clsx(
        "check-box",
        radio && "radio",
        className,
        !inactive && "selected",
        disabled && "disabled",
      )}
      onClick={clickOutsideCheck}
      tabIndex={-1}
    >
      <Button
        className="check"
        icon={checkIcon}
        depth="0.4rem"
        fontSize="1rem"
        bgColor={bgColor}
        variant={variant || (inactive && "secondary")}
        onClick={handleToggleBtn}
        tabIndex={disabled && -1}
        ref={checkRef}
      />
      <span
        className="label"
        style={{ marginRight: !info && "auto", display: !label && "none" }}
      >
        {label}
      </span>
      {info && (
        <Tooltip
          className={clsx("info-tooltip", className && `tt-${className}`)}
          content={info}
          appendTo={contentElement}
          trigger="mouseenter focus click"
          delay={[500, 0]}
          placement="right"
          onCreate={(inst) => (tooltipRef.current = inst)}
        >
          <IconInfoCircle
            className="info-hint"
            stroke={2.2}
            tabIndex={0}
            style={{ marginRight: info && "auto" }}
            onKeyDown={(e) => keyEnter(e, infoClick)}
          />
        </Tooltip>
      )}
      {after}
    </span>
  );
};

export default CheckBox;
