import { pause } from "@src/Utils";
import ScrollList from "@src/components/ScrollList";
import { OverlayScrollbars } from "overlayscrollbars";
import { useEffect, useState } from "react";

/** @type {ForwardRef<VirtualizedScrollListProps, HTMLDivElement>} */
const VirtualizedScrollList = (props) => {
  const {
    children,
    itemData,
    adapter,
    adapterProps = {},
    deps = [],
    safeArea = 2, // number of items
    debounceMS,
    onInit,
    onScroll,
    onUpdated,
    ...otherProps
  } = props;

  const [visibleCards, setVisibleCards] = useState({ first: -1, last: -1 });
  const [cardList, setCardList] = useState([]);
  const ItemAdapter = adapter;

  useEffect(() => {
    if (!itemData?.length) return setCardList([]);

    if (!cardList.length || visibleCards.first < 0 || visibleCards.last < 0) {
      const newCards = itemData?.map((user, idx) => (
        <ItemAdapter
          key={idx}
          item={user}
          visible={cardVisible(idx, visibleCards)}
          {...adapterProps}
        />
      ));

      pause(250).then(() => setCardList(newCards));
      return;
    }

    const newCards = [...cardList];
    const { first, last } = visibleCards;
    for (let idx = first - safeArea; idx <= last + safeArea; idx++) {
      if (idx < 0) continue;
      if (idx >= itemData?.length) break;

      const user = itemData[idx];
      const newIdx = Math.min(newCards.length, idx);
      const card = (
        <ItemAdapter
          key={newIdx}
          item={user}
          visible={cardVisible(newIdx, visibleCards)}
          {...adapterProps}
        />
      );

      if (idx >= newCards.length) {
        newCards.push(card);
      } else {
        newCards[idx] = card;
      }
    }

    setCardList(newCards);
  }, [itemData, visibleCards, ...deps]);

  /** @param {OverlayScrollbars} inst */
  function getElements(inst) {
    const viewport = inst.elements().viewport;
    /** @type {HTMLElement} */
    const container = viewport?.childNodes[1];
    /** @type {HTMLElement[]} */
    const cards = container?.childNodes;

    return { viewport, container, cards };
  }

  /** @param {OverlayScrollbars} inst */
  function calcListSpace(inst) {
    const { viewport, container, cards } = getElements(inst);

    if (!cards || !cards.length) return {};

    const above = parseInt(viewport.scrollTop);
    const below = container.offsetHeight - above - viewport.clientHeight;

    return { container, cards, above, below };
  }

  /** @param {OverlayScrollbars} inst */
  async function calcVisibleCards(inst) {
    const { container, cards, above, below } = calcListSpace(inst);

    let first = -1;
    let last = -1;

    if (!cards) return;

    for (let idx in cards) {
      const card = cards[idx];

      if (first < 0) {
        if (!cardBottomVisible(card, above)) continue;
        first = parseInt(idx);
        last = parseInt(idx);
      } else {
        if (!cardTopVisible(container, card, below)) break;
        last++;
      }
    }

    setVisibleCards({ first, last });
  }

  function cardBottomVisible(card, above) {
    if (!(card instanceof HTMLElement)) return;
    return card?.offsetTop + card?.clientHeight >= above;
  }

  function cardTopVisible(ctnr, card, below) {
    if (!(card instanceof HTMLElement)) return;
    return ctnr.offsetHeight - card?.offsetTop >= below;
  }

  function cardVisible(idx, cardWindow) {
    if (cardWindow.first < 0 || cardWindow.last < 0) return false;

    if (idx < cardWindow.first - safeArea) return false;
    if (idx > cardWindow.last + safeArea) return false;

    return true;
  }

  /** @param {OverlayScrollbars} inst */
  function handleScroll(inst, cardWindow) {
    const { container, cards, above, below } = calcListSpace(inst);
    let first = cardWindow.first;
    let last = cardWindow.last;

    if (!cards) return;

    while (cardBottomVisible(cards[first], above)) {
      if (first <= 0) break;
      first--;
    }
    while (!cardBottomVisible(cards[first], above)) {
      if (first >= cards.length) break;
      first++;
    }

    while (cardTopVisible(container, cards[last], below)) {
      if (last + 1 >= cards.length) break;
      last++;
    }
    while (!cardTopVisible(container, cards[last], below)) {
      if (last <= 0) break;
      last--;
    }
    if (first > last) first = last;

    if (first === cardWindow.first && last === cardWindow.last) return;
    setVisibleCards({ first, last });
  }

  return (
    <ScrollList
      onInit={(inst) => {
        onInit?.(inst);
        calcVisibleCards(inst);
      }}
      onUpdated={(inst, args) => {
        onUpdated?.(inst, args);
        if (visibleCards.first < 0 || visibleCards.last < 0) {
          calcVisibleCards(inst);
        } else {
          handleScroll(inst, visibleCards);
        }
      }}
      onScroll={(inst, event) => {
        onScroll?.(inst, event);
        handleScroll(inst, visibleCards);
      }}
      debounceMS={200}
      {...otherProps}
    >
      {cardList}
      {children}
    </ScrollList>
  );
};

export default VirtualizedScrollList;
