import { pause } from "@src/Utils";
import {
  IconChevronLeft,
  IconChevronRight,
  IconChevronsLeft,
  IconChevronsRight,
} from "@tabler/icons-react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Button } from "./Button";
import { SelectField } from "./InputField";

/**
 * @param {{
 *   page: number;
 *   numItems: number;
 *   shownRange: number;
 *   perPageOptions: number[];
 *   itemsPerPage: number;
 *   selectDisabled: boolean;
 *   onPageChange: (page: number) => void;
 *   onItemsPerPageChange: () => void;
 * }} props
 */
const PagerFooter = (props) => {
  const {
    page = 0,
    numItems = 0,
    shownRange = 2,
    itemsPerPage,
    perPageOptions,
    selectDisabled,
    onPageChange,
    onItemsPerPageChange,
  } = props;

  const [currPage, setCurrPage] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [pageList, setPageList] = useState([]);
  const [showPrevEllipse, setShowPrevEllipse] = useState(false);
  const [showNextEllipse, setShowNextEllipse] = useState(false);

  const depth = "0.5rem";
  const navFontSize = "1.3rem";
  const pageFontSize = "1.5rem";
  const navBtnStroke = 2.6;

  useEffect(() => {
    if (itemsPerPage < 1) return;
    const parsedNumPages = Math.max(Math.ceil(numItems / itemsPerPage), 0);
    const parsedPage = Math.min(Math.max(page, 1), parsedNumPages);
    setCurrPage(parsedPage);
    setNumPages(parsedNumPages);
    makePageList(parsedPage, parsedNumPages);
  }, [page, numItems, itemsPerPage, shownRange]);

  useEffect(() => {
    if (document.activeElement?.matches(".btn.page")) {
      document.querySelector(".btn.curr-page")?.focus();
    }
  }, [currPage]);

  async function updateCurrPage(newPage, delay = 0) {
    if (delay > 0) await pause(delay);
    onPageChange?.(newPage);
    setCurrPage(newPage);
  }

  function makePageList(currPage, numPages) {
    const newList = [];

    const pagesStart = Math.max(1, currPage - shownRange);
    const pagesEnd = Math.min(numPages, currPage + shownRange);
    let numPagesBefore = currPage - pagesStart;
    let numPagesAfter = pagesEnd - currPage;

    if (numPagesBefore < numPagesAfter) {
      numPagesAfter = 2 * shownRange - numPagesBefore;
    } else {
      numPagesBefore = Math.min(currPage - 1, 2 * shownRange - numPagesAfter);
    }
    setShowPrevEllipse(currPage - numPagesBefore > 1);
    setShowNextEllipse(currPage + numPagesAfter < numPages);

    for (let i = currPage - numPagesBefore; i < currPage; i++) {
      newList.push(
        <Button
          key={`${i}-page-before`}
          className="page page-before"
          variant="secondary"
          label={i}
          depth={depth}
          fontSize={pageFontSize}
          onClick={() => updateCurrPage(i, 100)}
        />,
      );
    }

    if (numPages > 0 && currPage > 0) {
      newList.push(
        <Button
          key="curr-page"
          className="page curr-page"
          label={currPage}
          depth={depth}
          fontSize={pageFontSize}
        />,
      );
    }

    for (let i = currPage + 1; i <= currPage + numPagesAfter; i++) {
      if (i > numPages) break;

      newList.push(
        <Button
          key={`${i}-page-after`}
          className="page page-after"
          variant="secondary"
          label={i}
          depth={depth}
          fontSize={pageFontSize}
          onClick={() => updateCurrPage(i, 100)}
        />,
      );
    }

    setPageList(newList);
  }

  function itemRangeLabel() {
    const start = Math.min(itemsPerPage * (currPage - 1) + 1, numItems);
    const end = Math.min(itemsPerPage * currPage, numItems);
    if (start <= 0 || end <= 0) return;

    let range = `${start.toLocaleString()}-${end.toLocaleString()}`;
    if (start === end) {
      range = `${start.toLocaleString()}`;
    }
    return `${range} of ${numItems.toLocaleString()}`;
  }

  return (
    <div className="pagination">
      <div className="item-ctrls">
        {numItems > 0 && <span className="num-items">{itemRangeLabel()}</span>}
        <div className="items-per-page">
          <SelectField
            id="items-per-page"
            value={itemsPerPage}
            options={perPageOptions}
            onChange={onItemsPerPageChange}
            width="5.5rem"
            disabled={selectDisabled}
          />
          <label htmlFor="items-per-page">
            Items <br /> per page
          </label>
        </div>
      </div>

      <div className="page-ctrls">
        <span
          className={clsx("page-ellipsis pad", showPrevEllipse && "hidden")}
        >
          ...
        </span>
        <Button
          className={clsx("nav", "to-first-page", currPage <= 2 && "hidden")}
          variant="secondary"
          depth={depth}
          fontSize={navFontSize}
          icon={<IconChevronsLeft stroke={navBtnStroke} />}
          onClick={() => updateCurrPage(1)}
          disabled={currPage <= 2}
        />
        <Button
          className="nav to-prev-page"
          variant="secondary"
          depth={depth}
          fontSize={navFontSize}
          icon={<IconChevronLeft stroke={navBtnStroke} />}
          onClick={() => updateCurrPage(currPage - 1)}
          disabled={currPage <= 1}
        />
        <span className={clsx("page-ellipsis", !showPrevEllipse && "hidden")}>
          ...
        </span>
        {pageList}
        <span className={clsx("page-ellipsis", !showNextEllipse && "hidden")}>
          ...
        </span>
        <Button
          className="nav to-next-page"
          variant="secondary"
          depth={depth}
          fontSize={navFontSize}
          icon={<IconChevronRight stroke={navBtnStroke} />}
          onClick={() => updateCurrPage(currPage + 1)}
          disabled={currPage >= numPages}
        />
        <Button
          className={clsx(
            "nav",
            "to-last-page",
            currPage + 1 >= numPages && "hidden",
          )}
          variant="secondary"
          depth={depth}
          fontSize={navFontSize}
          icon={<IconChevronsRight stroke={navBtnStroke} />}
          onClick={() => updateCurrPage(numPages)}
          disabled={currPage + 1 >= numPages}
        />
        <span
          className={clsx("page-ellipsis pad", showNextEllipse && "hidden")}
        >
          ...
        </span>
      </div>
    </div>
  );
};

export default PagerFooter;
