import clsx from "clsx";
import { ClickScrollPlugin, OverlayScrollbars } from "overlayscrollbars";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { duplicate, pause } from "../Utils";
import "../styles/layout.css";

/** @type {ForwardRef<ScrollListProps, HTMLDivElement>} */
const ScrollList = forwardRef((props, ref) => {
  const {
    className,
    direction,
    borderRadius,
    background,
    autoHide = "move",
    style,
    showEdge,
    children,
    debounceMS,
    onInit,
    onScroll,
    onUpdated,
    onDestroyed,
    defer,
  } = props;

  const [scrollList, setScrollList] = useState(null);
  const [edgeOpacity, setEdgeOpacity] = useState(0);
  const [loaded, setLoaded] = useState(false);

  OverlayScrollbars.plugin(ClickScrollPlugin);

  // prettier-ignore
  useImperativeHandle(ref,() => {
    return scrollList;
  }, [children]);

  useEffect(() => {
    if (!scrollList) return;
    const container = scrollList?.childNodes?.[1];

    if (!container) return;
    animateCards(container);
  }, [direction, children, scrollList]);

  /** @param {OverlayScrollbars} inst */
  function getElements(inst) {
    const viewport = inst?.elements().viewport;
    const container = viewport?.childNodes?.[1];
    return { viewport, container };
  }

  /** @type {typeof onInit} */
  function handleInit(inst) {
    const { viewport, container } = getElements(inst);
    setScrollList(viewport);

    if (!container) return;
    animateCards(container);

    if (!showEdge) return;
    checkEdge(container);
  }

  /** @param {OverlayScrollbars} inst */
  function handleUpdate(inst) {
    if (!showEdge) return;

    const { container } = getElements(inst);
    if (!container || !container.childNodes.length) return;

    checkEdge(container);
  }

  /** @param {Element} container */
  function checkEdge(container) {
    if (direction === "vertical") {
      verticalEdgeOpacity(container);
    } else {
      horizontalEdgeOpacity(container);
    }
  }

  /** @param {Element} container */
  function verticalEdgeOpacity(container) {
    const content = container.parentElement;
    const overflowHeight = content.scrollHeight - content.offsetHeight;
    const scrollBottom = overflowHeight - parseInt(content.scrollTop);
    const cardHeight = container.lastElementChild.offsetHeight;
    const tallerThanCard = content.clientHeight > 1.2 * cardHeight;
    setEdgeOpacity(scrollBottom > 5 && tallerThanCard ? 1 : 0);
  }

  /** @param {Element} container */
  function horizontalEdgeOpacity(container) {
    const content = container.parentElement;
    const overflowWidth = content.scrollWidth - content.offsetWidth;
    const scrollRight = overflowWidth - parseInt(content.scrollLeft);
    setEdgeOpacity(scrollRight > 5 ? 1 : 0);
  }

  /** @param {Element} container */
  async function animateCards(container) {
    await pause(200);
    setLoaded(true);
    for (const card of Array.from(container.children)) {
      card.style.opacity = 1;
      card.classList.add("visible");
      await pause(20);
    }
  }

  return (
    <OverlayScrollbarsComponent
      className={clsx("scroll-list", className, direction)}
      options={{
        scrollbars: { autoHide, clickScroll: true },
        update: debounceMS && { debounce: debounceMS },
      }}
      events={{
        initialized: [onInit, handleInit],
        scroll: [onScroll, handleUpdate],
        updated: [onUpdated, handleUpdate],
        destroyed: onDestroyed,
      }}
      style={{
        "--list-border-radius": borderRadius,
        "--list-bg-color": background,
        opacity: loaded ? 1 : 0,
        ...style,
      }}
      defer={defer}
    >
      <div className="rounded-mask" />
      <div className="list-container">{children}</div>
      {showEdge && (
        <span
          className={clsx("scroll-list-edge", direction)}
          style={{ opacity: edgeOpacity }}
        >
          {duplicate(<div />, 5)}
        </span>
      )}
    </OverlayScrollbarsComponent>
  );
});

export default ScrollList;
